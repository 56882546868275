import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../App.css";
import HeaderBack from "../../components/Header/HeaderBack";
import { getGender } from "../../handlers/dictionaryHandler.js";

const EditDetails = () => {
  const [aboutMeGood, setAboutMeGood] = useState(0);
  const [aboutPartnerGood, setAboutPartnerGood] = useState(0);
  const [mirorGood, setMirorGood] = useState(0);
  const [picGood, setPicGood] = useState(1);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/pAreDetailseGood",
        config
      );

      const userData = response.data;

      setAboutMeGood(userData.aboutMeGood);
      setAboutPartnerGood(userData.aboutPartnerGood);
      setMirorGood(userData.mirorGood);
      setPicGood(userData.picGood);
      // setHaredGood(userData.haredGood);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  return (
    <form className="forum-main-form">
      <HeaderBack />

      <br></br>

      <div className="form-body">
        <div id="h1">עריכת הפרטים</div>

        <div className="">
          <Link to={"/aboutme"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">מי אני</div>
              <div className="details-not-good">
                {!aboutMeGood ? "חסר!" : ""}
              </div>
              <div
                className={
                  getGender() === "1" ? "aboutmeiconw" : "aboutmeiconm"
                }
              />
            </div>
          </Link>
          <Link to={"/aboutpartner"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">מי מתאים לי</div>
              <div className="details-not-good">
                {!aboutPartnerGood ? "חסר!" : ""}
              </div>
              <div
                className={
                  getGender() === "1"
                    ? "aboutpartnericonw"
                    : "aboutpartnericonm"
                }
              />
            </div>
          </Link>
          <Link to={"/miror"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">מראה מראה שעל הקיר</div>
              <div className="details-not-good">{!mirorGood ? "חסר!" : ""}</div>
              <div className={"miroricon"} />
            </div>
          </Link>

          <Link to={"/pics"} style={{ textDecoration: "none" }}>
            <div className="collabbtn ">
              <div className="collabtntext">תמונות ועוד</div>
              <div className="details-not-good">{!picGood ? "חסר!" : ""}</div>
              <div className={"picicon"} />
            </div>
          </Link>
          <br />
          {/* <div className="faceline">
            <a
              href={"https://wa.me/972549624084"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="whatsuplink"></div>
            </a>
            <div className="lbl">
              רוצים התראה לווטסאפ כשיש התאמה? רשמו לנו הודעה
              <br />
              <div className="smalllbl">
                * ההודעות בווטסאפ בדכ מגיעות, בכפוף למדיניות של ווטסאפ. ההודעות
                במייל מגיעות תמיד.
              </div>
              <div className="smalllbl">
                * ברירת המחדל - משלוח מסרוני ווטסאפ מעת לעת לקידום הפעילות באתר.
              </div>
            </div>
          </div> */}

          <Link to="/questionnaires" style={{ textDecoration: "none" }}>
            <div className="button" id="backBtn">
              {" "}
              חזרה
            </div>
          </Link>
          <div className="lineDice" />
        </div>
      </div>
    </form>
  );
};

export default EditDetails;
