import React, { useEffect, useState } from "react";
import axios from "axios";
import FileManager from "../../utils/FileManager.js";
import {
  getWordOfOtherGender,
  getHobby,
} from "../../handlers/dictionaryHandler.js";

const GetOneProfile = ({ otherId }) => {
  const [details1, setDetails1] = useState("");
  const [freeText1, setfreeText1] = useState("");
  const [cellFriend1, setCellFriend1] = useState("");
  const [image1a, setImage1a] = useState("");
  const [image1aBlobURL, setImage1aBlobURL] = useState("");
  const [religous1, setReligous1] = useState("");
  const [tall1, setTall1] = useState("");
  const [education1, setEducation1] = useState("");
  const [status1, setStatus1] = useState("");
  const [hobbies1, setHobbies1] = useState("");
  const [hobbieFree, setHobbieFree] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [origin1, setOrigin1] = useState("");
  const [idProfile, setIdProfile] = useState("1");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        // const response = await axios.get(
        //   process.env.REACT_APP_LOCALHOST_ADDRESS + "/PgetProfile",
        //   config
        // );
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/getOneProfile",
          { idProfile: otherId },
          config
        );
        const userData = response.data;

        if (userData[0].length > 0) {
          setDetails1(userData[0][0].details);
          setfreeText1(userData[0][0].freeText);
          setCellFriend1(userData[0][0].cellFriend);
          if (userData[0][0].keyImage1 !== "") {
            const image1Data = await FileManager.getFile(
              userData[0][0].keyImage1
            );
            setImage1a(image1Data.file);
            setImage1aBlobURL(image1Data.blobURL);
          }
          setReligous1(userData[0][0].religous);
          setTall1(userData[0][0].tall);
          setEducation1(userData[0][0].education);
          setStatus1(userData[0][0].status);
          setOccupation1(userData[0][0].occupation);
          setOrigin1(userData[0][0].origin);
          setHobbies1(userData[0][0].hobbies);
          setHobbieFree(userData[0][0].hobbieFree);
        } else {
          // in case there is no data
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    };
    loadData();
  }, []);

  return (
    <form className="supermatchmaker-form">
      <div className="signup-body">
        <div
          className="button-text-center-no-hover"
          id="btnMatch"
          //   onClick={infoBtnClick}
        >
          {details1}
        </div>

        <div></div>
        <div className="faceline" style={{ maxWidth: "100%" }}>
          {image1a !== "" ? (
            <>
              <img
                className={"image-doubleMatch"}
                src={image1aBlobURL}
                alt=""
              />
            </>
          ) : null}
          <div>
            <h5> מי אני</h5>
            <div className="messegeMatch">
              {getWordOfOtherGender("pdat" + religous1)} , {tall1} ס'מ ,{" "}
              {getWordOfOtherGender("psta" + status1)}
            </div>
            <div className="messegeMatch">
              השכלה:
              {getWordOfOtherGender("pedu" + education1)}
            </div>
            <div className="messegeMatch">
              עיסוק:
              {occupation1}
            </div>
            <div className="messegeMatch">
              מוצא:
              {origin1}
            </div>
            <h5> התחביבים שלי</h5>
            <div className="messegeMatch">
              {hobbies1 != null
                ? hobbies1
                    .split(",")
                    .map((current) => getHobby(current))
                    .join(", ")
                : ""}
              {""}
              {hobbieFree != null ? ", " : ""}
              {hobbieFree}
            </div>
          </div>
          <div> </div>
        </div>

        <h5> טקסט חופשי</h5>
        <div className="messegeMatch"> {freeText1}</div>
        <h5> טלפון לבירורים </h5>
        <div className="messegeMatch"> {cellFriend1}</div>
        <br />
      </div>
    </form>
  );
};

// לאפשר ליצור קשר עם מועמד עי סמס רק אם אתה מנוי.
// ליצור חלון שהצד השני יראה את ההתאמה הקודמת
// לאפשר לראות התאמה קודמת רק אם הצד השני לא אמר שלא מתאים

export default GetOneProfile;
